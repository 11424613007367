.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 1px 0 rgba(22, 8, 43, 0.1);
  padding: 0 1rem;
  z-index: var(--z-fixed);
  transition: 0.4s;
  background-color: #f8f9fa;
}
.header .header__container {
  display: flex;
  align-items: center;
  height: var(--header-height);
  justify-content: space-between;
}

.header .header__container .header__toggle {
  color: var(--title-color);
  cursor: pointer;
  font-size: 1.2rem;
  color: #0a0908;
}
.header .header__container .HeaderControls {
  display: flex;
  align-items: center;
  gap: .5em;
}
.header .header__container .HeaderControls .DownloadAppButton {
  background-color: var(--PrimaryColor) !important;
  color: var(--WhiteColorPrimary); 
  transition: 0.4s ease;
}
.header .header__container .HeaderControls .DownloadAppButton:hover {
  background-color: var(--SecondaryColor) !important;
}
.header .header__container .HeaderControls .LanguageSwitcher, .header .header__container .HeaderControls .AppThemeModeSwitcher {
  color: var(--PrimaryColor) !important;
}
.header .header__container .HeaderControls .mantine-Paper-root {
  background-color: var(--WhiteColorSecondary);
}
.header .header__container .header__logo {
  color: var(--title-color);
  font-weight: var(--font-medium);
  display: none;
}
.TheOne__Nav {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  background-color: var(--container-color);
  box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1);
  z-index: var(--z-fixed);
  transition: 0.4s;
  background-color: #f8f9fa;
  color: white;
}
.TheOne__Nav .nav__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3rem;
  overflow: auto;
  scrollbar-width: none;
}
.TheOne__Nav .nav__container::-webkit-scrollbar {
  display: none;
}
.TheOne__Nav .nav__container .nav__logo {
  font-weight: var(--font-semi-bold);
  font-size: var(--medium-font-size);
  margin-bottom: 1.1rem;
  white-space: nowrap;
  font-family: 'poppins';
}
.TheOne__Nav .nav__container .nav__list, .TheOne__Nav .nav__container .nav__items {
  display : grid;
}
.TheOne__Nav .nav__container .nav__list {
  row-gap: 1.2rem;
}
.TheOne__Nav .nav__container .nav__items {
  row-gap: .9rem;
}
.TheOne__Nav .nav__container .nav__link {
  display: flex;
  align-items: center;
  color: #0a0908;
}
.TheOne__Nav .nav__container .nav__link:hover {
  color: #0c0f0a;
}
.TheOne__Nav .nav__container .nav__link .nav__icon {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}
.TheOne__Nav .nav__container .nav__link .nav__name {
  font-size: var(--medium-font-size);
  font-weight: var(--font-medium);
  white-space: nowrap;
  font-family: 'poppins';
}
.show-menu {
  left: 0;
}
.active {
  color: var(--first-color);
}
@media screen and (max-width: 767px) {
  .HeaderControls {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 768px) {
  .header {
    padding: 0 3rem 0 6rem;
  }
  .header .header__container {
    height: calc(var(--header-height) + 0.5rem);
  }
  .header .header__container .header__toggle {
    display: none;
  }
  .header .header__container .header__logo {
    display: block;
  }
  .header .header__container .header__img {
    width: 40px;
    height: 40px;
  }
  .header .header__container .HeaderControls {
    order: 1;
  }
  .TheOne__Nav {
    left: 0;
    padding: 1.2rem 1.5rem 0;
    width: 72px;
    
  }
  .TheOne__Nav .nav__items {
    row-gap: 1.7rem;
  }
  .TheOne__Nav .nav__items .nav__icon {
    font-size: 1.3rem;
  }
  .TheOne__Nav .nav__logo-name,
  .TheOne__Nav .nav__name,
  .TheOne__Nav .nav__subtitle,
  .TheOne__Nav .nav__dropdown-icon {
    opacity: 0;
    transition: 0.3s;
    color: #0a0908;
  }
  .TheOne__Nav:hover {
    width: var(--nav-width);
  }
  .TheOne__Nav:hover .nav__logo-name {
    opacity: 1;
  }
  .TheOne__Nav:hover .nav__subtitle {
    opacity: 1;
  }
  .TheOne__Nav:hover .nav__name {
    opacity: 1;
  }
  .TheOne__Nav:hover .nav__dropdown-icon {
    opacity: 1;
  }
}
