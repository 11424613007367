.Img-Devotion figure img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.Img-Devotion figure:hover img {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
    cursor: pointer;
}
@media screen and (max-width: 600px) {
	.ChapterBtn, .VerseBtn{
		width: 25% !important;
	}
	.VersionBTN{
		width: 40% !important;
	}
	.VerseContent{
		width: 100% !important ;
		text-align: left !important;
	}
}
