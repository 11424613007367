@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} 
@font-face {
  font-family: OpenSansRegular;
  src: url("../fonts/OpenSans-Regular.ttf");
}
:root {
  --PrimaryColor: #412E9D; 
  --SecondaryColor: #33218d;
  --DarkColor: #1C2327;
  --WhiteColorPrimary: #ffffff;
  --WhiteColorSecondary: #f2f2f2;
  --GreyColor: #808080;
  --GreyColorSecondary: #dddddd;
  --RedColorPrimary: rgb(204, 0, 0);
  --RedColorSecondary: rgb(167, 4, 4);
  --DMBGColor: #202122;
  --DMPrimaryColor: #161111;
  --DMSecondaryColor: #2b2b2b;
  --DMSecondaryColorOnHover: #363636;
  --DMShadowColor: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --DMShadowColorSecondary: rgba(0, 0, 0, 1) 0px 7px 29px 0px;
  --Primary: #4E37BA;
  --Secondary: #FFD710;
  --HeartColor: #FA456C;
  --HeartIconColor: linear-gradient(360deg, rgb(240, 29, 58) 0%, rgba(243,107,126,1) 100%);
  --CommentIconColor: linear-gradient(360deg, rgb(47, 158, 45) 0%, rgb(95, 228, 108) 100%);
  --InviteIconColor: linear-gradient(360deg, rgba(253,126,20,1) 0%, rgb(255, 128, 24) 100%);
  --AcceptedIconColor: linear-gradient(360deg, rgba(103,51,255,1) 0%, rgba(148,112,255,1) 100%);
  --ActiveCommentBGColor: #d6e6f7;
  --header-height: 3.5rem;
  --nav-width: 219px;
  /*========== Colors ==========*/
  --first-color: #6923D0;
  --first-color-light: #F4F0FA;
  --title-color: #19181B;
  --text-color: #58555E;
  --text-color-light: #A5A1AA;
  --body-color: #F9F6FD;
  --container-color: #FFFFFF;
  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif;
  --normal-font-size: .938rem;
  --small-font-size: .75rem;
  --smaller-font-size: .75rem;
  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /*========== z index ==========*/
  --z-fixed: 100;
}
@media screen and (min-width: 1024px) {
  :root {
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }
}
body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  margin: var(--header-height) 0 0 0;
  padding: 2rem 2rem 0;
  color: var(--text-color);
  scroll-behavior: smooth;
  background-color: white;
}
.rdt_Table { 
  width: 100%; 
  border-collapse: collapse; 
}
.rdt_TableCol { 
  background-color: #6c2be2;
  color: white; 
  font-weight: bold; 
}
.rdt_TableRow, .rdt_TableBody, .rdt_Pagination { 
  padding: 6px; 
  background: white;
  text-align: left; 
}
.rdt_Pagination{
  color: #161111 !important;
  font-weight: 800;
  font-size: 24 !important;
}
#pagination-first-page, #pagination-previous-page, #pagination-next-page, #pagination-last-page{
  background-color: rgb(223, 223, 223);
  margin: 3px;
}
#pagination-first-page:hover, #pagination-previous-page:hover, #pagination-next-page:hover, #pagination-last-page:hover{
  background-color: #bebebe;

}
.rdt_TableRow:hover{
  cursor: pointer;
  background: linear-gradient(to bottom right, #bb98ff, #ddcbff);
}
.ImageContainer {
  position: relative;
  width: 100%;
}
.CoverImage{
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  box-shadow: 1px 2px 5px 3px rgb(114, 110, 110);
}
.PlayBtnContainer{
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.ImageContainer:hover .CoverImage{
  opacity: 0.1;
}
.ImageContainer:hover .PlayBtnContainer{
  opacity: 1;
}
.MobileLayout{
  display: none;
}
/* .mantine-Select-input {
  background-color: #4a1e9e;
  color: white;
  font-family: 'poppins';
} */
@media screen and (max-width: 900px) {
  .FirstContainer{
    padding : 0 !important;
  }
  .PageTitle{
    text-align: center;
  }
  .PostContent{
    width: 100% !important;
  }
  .FirstContainer{
    padding : 0 !important;
  }
  .SecondContainer{
    padding : 0 !important;
    padding-top: 25px !important;
  }
  .TotalUserChart, .DailyDevotionChart{
    padding: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .Overlay{
    width: 100% !important;
  }
  .BibleStoryImage{
    max-width: 100% !important; 
  }
  .WorshipContent, .ActivityLogsContent{
    width: 100% !important;
  }
  .FullNameContent{
    text-align: 'center' !important;
  }
  .VerseContentWoftd{
    display: none;
  }
  .WorshipContent{
    padding: 0 !important;
  }
  .RemoveIcon{
    display: none !important;
  }
  .PlanContent, .AchievementContent, .GroupContent{
    width: 100% !important;
  }
  .WordOfTheDayVerse {
    max-width: 100% !important;
  }
  .ListOfAudioContent, .ListOfUserContent, .ListOfChurchContent, .ListOfReasonContent{
    width: 220px !important;
  }
  .ImageContainer{
    width: 100% !important;
  }
  .EventContent, .BlogsContent, .CalendarEventContent{
    max-width: 80% !important;
  }
  .UserProfileContent, .UserAchievementTaskContent{
    width: 100% !important;
  }
  .WorshipContentImage{
    width: 100% !important;
  }
  .WoftImage{
    max-width: 100% !important;
  }
  .UserImage, .WebLayout, .VersionName{
    display: none;
  }
  .MobileLayout{
    display: block;
  }
  .ResourcesLayout{
    width: 50% !important;
  }
  .ResourcesTitle{
    max-width: 70% !important;
  }
  .BookContent{
    width: 100% !important;
  }
  
}