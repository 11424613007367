
.ImageContainer {
    position: relative;
    width: 100%;
  }
  .BookCoverImage{
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    box-shadow: 1px 2px 5px 3px rgb(114, 110, 110);
  }
  .ViewBtnContainer{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  .ImageContainer:hover .BookCoverImage{
    opacity: 0.3;
  }
  .ImageContainer:hover .ViewBtnContainer{
    opacity: 1;
}
@media screen and (max-width: 600px) {
    .TitleNotFound{
        width: 100% !important;
    }
    .ListOfBook{
        width: 100% !important;
    }
    .BookContent{
        width: 100% !important;
    }
 }